import { client } from './client'

interface UserInfo {
  id: string
  displayName: string
  givenName: string
  surname: string
  email: string
  jobTitle: string
  legacyUsername: string
  officeLocation?: string | null
  department: string
}

interface GetUserInfoResponse {
  items?: UserInfo[]
  message?: string
}

export const getUserInfo = async (
  aegisApiUrl: string,
  accessToken: string,
  username: string
): Promise<UserInfo | null> => {
  const url = `${aegisApiUrl}?startsWith=${username}`
  const { message, items } = await client.get<GetUserInfoResponse>(url, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  if (message) {
    console.error(`Error fetching aegis user info: ${message}`)
  }
  return items ? items[0] : null
}
