import jwt, { JwtPayload } from 'jwt-decode'
import { useEffect } from 'react'

import { getConfig } from '../config'
import { getUserInfo } from '../util/api/aegis'
import { loadPendo } from './loadPendo'

interface PendoProps {
  accessToken: string
  techSolutionId: string
  techSolutionName: string
}

export const Pendo = ({ accessToken, techSolutionId, techSolutionName }: PendoProps): null => {
  const config = getConfig()

  useEffect(() => {
    const fetchUserAndAttachScript = async (name: string, email: string) => {
      const userInfo = await getUserInfo(config.aegisApiUrl, accessToken, email)

      loadPendo(email, name, userInfo?.jobTitle, techSolutionId, techSolutionName, config.env)
    }

    try {
      const user = jwt<JwtPayload>(accessToken)
      const email = user?.sub
      if (email) {
        const [name] = email.split('@nike.com')
        void fetchUserAndAttachScript(name, email)
      }
    } catch (error) {
      console.error('Error parsing user info', error)
    }
  }, [accessToken, techSolutionId])

  return null
}
