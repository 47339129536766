import { NarkPlatform } from '@nike/nark-sdk'

type NavNarkType = Record<string, unknown>

export class NavNarkPlatform extends NarkPlatform {
  constructor(options: NavNarkType) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    super(options)
  }

  logException = (metric: NavNarkType): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    super.log({
      feature: 'integrated.platform',
      operation: 'general.exception',
      ...metric,
    })
  }

  logMicroNavException = (metric: NavNarkType): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    super.log({
      feature: 'micro.nav',
      operation: 'micro.exception',
      ...metric,
    })
  }

  logMicroLoadBackup = (metric: NavNarkType): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    super.log({
      feature: 'micro.nav',
      operation: 'load.backup',
      ...metric,
    })
  }
}

export const createNavNarkPlatform = (narkConfig: NavNarkType): NavNarkPlatform =>
  new NavNarkPlatform(narkConfig)
