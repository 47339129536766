import moment from 'moment'
import { useState, useEffect, useRef, useContext } from 'react'
import ReactMarkdown from 'react-markdown'

import { Icon, Text } from '@nike/eds'

import { MarkdownComponents } from '../components/MarkdownComponents'
import { getConfig } from '../config'
import { ReleaseNote, updateUserReleaseNotesLastSeen } from '../util/api/pc'
import { NarkContext } from '../util/nark'
import { Badge } from './Badge'
import styles from './releaseNotes.styl'

const NewChip = () => (
  <Text font='body-3' className={styles.newChip}>
    New
  </Text>
)

const isNewReleaseNote = (dateReleased: string, releaseNotesLastSeen: string): boolean =>
  !releaseNotesLastSeen ||
  new Date(dateReleased).getTime() > new Date(releaseNotesLastSeen).getTime()

// This will filter out and sort release notes which have release date in the future
const sortedNotes = (releaseNotes: ReleaseNote[]) =>
  releaseNotes
    .sort((a, b) => new Date(b.dateReleased).getTime() - new Date(a.dateReleased).getTime())
    .filter((note) => {
      const today = new Date()
      const dateReleased = new Date(note.dateReleased)

      return today > dateReleased
    })

interface ReleaseNotesProps {
  releaseNotes: ReleaseNote[]
  techSolutionId: string
  name?: string
  newReleaseNotesCount?: number
  releaseNotesLastSeen?: string
  accessToken: string
}

export const ReleaseNotes = ({
  releaseNotes,
  techSolutionId,
  name,
  newReleaseNotesCount,
  releaseNotesLastSeen,
  accessToken,
}: ReleaseNotesProps): JSX.Element | null => {
  const nark = useContext(NarkContext)

  const drawerRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [unreadReleaseNotesCount, setUnreadReleaseNotesCount] = useState<number | undefined>(
    newReleaseNotesCount
  )

  const handleClick = () => {
    if (!open) {
      setUnreadReleaseNotesCount(0)
      try {
        void updateUserReleaseNotesLastSeen({
          platformConsoleApiUrl: getConfig().platformConsoleApiUrl,
          input: {
            techSolutionId,
          },
          accessToken: accessToken,
        })
      } catch (error) {
        console.error('Error requesting update user release notes last seen', error)
      }
      nark.logException({
        source: 'ui',
        operation: 'click',
        feature: 'techSolution.releaseNotes',
        data: { techSolutionId, name },
      })
    }
    setOpen(!open)
  }

  // Close release notes on outside click

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      const { target } = event
      if (
        open &&
        !drawerRef.current?.contains(target as Node) &&
        !triggerRef.current?.contains(target as Node)
      ) {
        setOpen(false)
        event.stopPropagation()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [open, drawerRef, triggerRef])

  if (!releaseNotes.length) return null

  return (
    <>
      <button
        ref={triggerRef}
        className={`${styles.iconButton} ${styles.releaseNotesButton} ${open ? styles.open : ''}`}
        onClick={handleClick}
      >
        {unreadReleaseNotesCount ? (
          <span className={`${styles.badgeContainer}`}>
            <Icon name='Notification' />
            <Badge content={unreadReleaseNotesCount} />
          </span>
        ) : (
          <Icon name='Notification' />
        )}
      </button>
      <div
        aria-hidden={!open}
        ref={drawerRef}
        className={`${styles.drawer} ${open ? styles.open : ''}`}
      >
        <div className={styles.drawerHeader}>
          <Text as='h3' font='title-4'>
            Release Notes
          </Text>
          <button
            aria-label='Close Release Notes'
            className={`${styles.iconButton} ${styles.closeButton}`}
            onClick={() => setOpen(false)}
          >
            <Icon name='Close' />
          </button>
        </div>
        <ul className={styles.noteList}>
          {sortedNotes(releaseNotes).map((note) => (
            <li key={`${note.dateReleased}-${note.title}`} className={styles.note}>
              <Text className={styles.date} as='div' font='body-3'>
                {moment(note.dateReleased).format('LL')}
              </Text>
              <div className={styles.headerContainer}>
                {isNewReleaseNote(note?.dateReleased ?? '', releaseNotesLastSeen ?? '') && (
                  <NewChip />
                )}
                <Text className={styles.title} as='h4' font='subtitle-1'>
                  {note.title}
                </Text>
              </div>
              <Text className={`${styles.body} PC-MARKDOWN`} as='div' font='body-3'>
                <ReactMarkdown components={MarkdownComponents}>{note.markdown}</ReactMarkdown>
              </Text>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}
