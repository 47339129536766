export type Query<I = void> = {
  operationName: string
  variables: {
    id?: string
    techSolutionId?: string
    input?: I
  }
  query: string
}

export const getTechSolutionQuery = (techSolutionId: string): Query => ({
  operationName: 'techSolution',
  variables: {
    id: techSolutionId,
  },
  query: `
    query techSolution($id: ID!) {
      techSolution(id: $id) {
        id
        name
      }
    }
`,
})

export const getReleaseNotesQuery = (techSolutionId: string): Query => ({
  operationName: 'techSolution',
  variables: {
    id: techSolutionId,
  },
  query: `
    query techSolution($id: ID!) {
      techSolution(id: $id) {
        name
        releaseNotes {
          title
          markdown
          dateReleased
        }
      }
    }
`,
})

export const getUserQuery = (techSolutionId: string): Query => ({
  operationName: 'fetchUser',
  variables: {
    techSolutionId,
  },
  query: `
    query fetchUser($techSolutionId: String!) {
      user(techSolutionId: $techSolutionId) {
        newReleaseNotesCount
        releaseNotesLastSeen
      }
    }
`,
})
