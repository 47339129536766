import jwt, { JwtPayload } from 'jwt-decode'
import { useEffect } from 'react'

import { getConfig } from '../config'
import { getUserInfo } from '../util/api/aegis'
import { loadQualtrics } from './loadQualtrics'
import { setQualtricsData } from './utils'

interface QualtricsProps {
  accessToken: string
  qualtricsId: string
  techSolutionName: string
}

export const Qualtrics = ({
  accessToken,
  qualtricsId,
  techSolutionName,
}: QualtricsProps): JSX.Element => {
  const config = getConfig()

  useEffect(() => {
    loadQualtrics(qualtricsId)
    setQualtricsData({ techSolution: techSolutionName })
  }, [qualtricsId])

  useEffect(() => {
    const fetchUserAndSend = async (firstName: string, lastName: string, email: string) => {
      const userInfo = await getUserInfo(config.aegisApiUrl, accessToken, email)

      setQualtricsData({
        firstName,
        lastName,
        email,
        jobTitle: userInfo?.jobTitle || undefined,
      })
    }

    try {
      const user = jwt<JwtPayload>(accessToken)
      const email = user?.sub
      if (email) {
        const [name] = email.split('@nike.com')
        const [firstName, lastName] = name.split('.')
        void fetchUserAndSend(firstName, lastName, email)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error parsing user info', error)
    }
  }, [accessToken])

  return <div id={qualtricsId} />
}
