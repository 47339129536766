import { RecentInput, ReleaseNotesLastSeenInput } from '../api/pc'
import { Query } from './queries'

export const updateUserRecentsMutation = (input: RecentInput): Query<RecentInput> => ({
  operationName: 'updateUserRecents',
  variables: {
    input,
  },
  query: `
    mutation updateUserRecents($input: RecentInput!) {
      updateUserRecents(input: $input) {
        id
      }
    }
`,
})

export const updateUserReleaseNotesLastSeenMutation = (
  input: ReleaseNotesLastSeenInput
): Query<ReleaseNotesLastSeenInput> => ({
  operationName: 'updateUserReleaseNotesLastSeen',
  variables: {
    techSolutionId: input.techSolutionId,
  },
  query: `
    mutation updateUserReleaseNotesLastSeen($techSolutionId: String!) {
      updateUserReleaseNotesLastSeen(techSolutionId: $techSolutionId) {
        releaseNotesLastSeen
      }
    }
`,
})
