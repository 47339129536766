import { useEffect, useState, ReactNode, AnchorHTMLAttributes } from 'react'

import { NavBar as EpicNavBar } from '@nike/epic-react-ui'

import { setQualtricsData } from '../Qualtrics'
import { ReleaseNotes } from '../ReleaseNotes'
import { getConfig } from '../config'
import {
  getReleaseNotes,
  getUser,
  ReleaseNotesResponse,
  TechSolutionWithReleaseNotes,
  User,
  UserResponse,
} from '../util/api/pc'

interface MenuItem {
  text: string
  path?: string
  title?: string
  search?: string
  exact?: boolean
  external?: boolean
}

interface Route {
  text: string
  path?: string
  search?: string
  exact?: boolean
  external?: boolean
  menuItems?: MenuItem[]
}

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string
  basePath?: string
  children?: ReactNode
}

export interface NavBarConfig {
  name?: string
  fixed?: boolean
  rootPath?: string
  menuType?: 'expand' | 'mega' | false
  searchPlaceholder?: string
  routes?: Route[]
  aside?: ReactNode
  RouterLink?: (props: LinkProps) => JSX.Element
  onSearch?: () => void
}

interface NavBarProps {
  accessToken: string
  techSolutionId?: string
  config?: NavBarConfig
}

export const NavBar = ({ techSolutionId, accessToken, config = {} }: NavBarProps): JSX.Element => {
  const { platformConsoleApiUrl } = getConfig()
  const [techSolution, setTechSolution] = useState<TechSolutionWithReleaseNotes>()
  const [user, setUser] = useState<User>()
  const [loadingReleaseNotes, setLoadingReleaseNotes] = useState(true)
  const [loadingUser, setLoadingUser] = useState(true)
  const {
    routes,
    rootPath,
    RouterLink,
    aside,
    onSearch,
    searchPlaceholder,
    name,
    menuType,
    fixed,
  } = config

  useEffect(() => {
    if (techSolutionId && accessToken) {
      const fetchReleaseNotes = async () => {
        try {
          setLoadingReleaseNotes(true)
          const response: ReleaseNotesResponse = await getReleaseNotes({
            platformConsoleApiUrl,
            techSolutionId,
            accessToken,
          })
          setTechSolution(response.techSolution)
          setQualtricsData({ techSolution: response.techSolution.name })
        } catch (error) {
          console.error('Error fetching release notes', error)
        } finally {
          setLoadingReleaseNotes(false)
        }
      }
      void fetchReleaseNotes()

      const fetchUser = async () => {
        try {
          setLoadingUser(true)
          const response: UserResponse = await getUser({
            platformConsoleApiUrl,
            techSolutionId,
            accessToken,
          })
          setUser(response.user)
        } catch (error) {
          console.error('Error fetching user', error)
        } finally {
          setLoadingUser(false)
        }
      }
      void fetchUser()
    }

    return () => {
      setQualtricsData({ techSolution: null })
    }
  }, [techSolutionId, accessToken])

  const loading = loadingReleaseNotes || loadingUser

  const navProps = {
    name: techSolution?.name || name,
    menuType,
    routes: routes || [],
    rootPath,
    RouterLink,
    aside:
      !loading && techSolution?.releaseNotes?.length ? (
        <>
          {aside}
          <ReleaseNotes
            releaseNotes={techSolution.releaseNotes}
            techSolutionId={techSolutionId ?? ''}
            name={techSolution?.name || name}
            newReleaseNotesCount={user?.newReleaseNotesCount}
            releaseNotesLastSeen={user?.releaseNotesLastSeen}
            accessToken={accessToken}
          />
        </>
      ) : (
        aside
      ),
    onSearch,
    searchPlaceholder,
    fixed,
  }

  return <EpicNavBar {...navProps} />
}
