import { detect } from 'detect-browser'
import { FC, ComponentType, ReactNode } from 'react'

import { UnsupportedBrowser, UnsupportedBrowserProps } from './UnsupportedBrowser'
import { eapBrowserSupport } from './util'

interface SupportedBrowsersProps {
  children: ReactNode
  component?: ComponentType<UnsupportedBrowserProps>
}

export const SupportedBrowsers: FC<SupportedBrowsersProps> = ({ component, children }) => {
  const browser = detect()

  if (!browser) {
    console.warn(
      'No Browser detected. If you are seeing this warning in your testing environment, see https://github.com/ariesjia/jest-useragent-mock#readme for a fix if you are experiencing an error releated to browser support.'
    )
    return null
  }

  const checkBrowserSupport = () => eapBrowserSupport(browser)

  const result = checkBrowserSupport()

  if (result.supported) {
    return <>{children}</>
  } else {
    if (component) {
      const CustomComponent = component
      return (
        <CustomComponent name={browser.name} version={browser.version} message={result.message} />
      )
    } else {
      return (
        <UnsupportedBrowser
          name={browser.name}
          version={browser.version}
          message={result.message}
        />
      )
    }
  }
}
