import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import { ErrorMessage } from '../components/ErrorMessage/index'
import { Spinner } from '../components/Spinner/index'
import { setConfig, getConfig, AppConfig, EnvType } from '../config'

interface HomePageProps {
  techSolutionId: string
  accessToken: string
  navigate?: (href: string) => void
  onError?: (error: Error) => void
  env?: EnvType // Optional override to environment configs
}

type HomePageComponent = (props: HomePageProps) => JSX.Element

interface HomePageState {
  hasError: boolean
  hasScriptError: boolean // Error state specifically for nav script onerror
  homePageLoaded: boolean
  primaryScriptAttempted: boolean
  HomePage?: HomePageComponent
}

interface OnScriptError {
  (): void
  (error?: Error): void
}

interface HomePageInitEvent extends Event {
  detail: HomePageComponent
}

class HomePageSource extends Component<HomePageProps, HomePageState> {
  public static defaultProps = {
    useConsoleLoginScreen: true,
    accessToken: '',
    name: '',
    showTopNav: true,
  }

  state: HomePageState = {
    hasError: false,
    hasScriptError: false,
    homePageLoaded: false,
    primaryScriptAttempted: false,
    // @ts-expect-error
    HomePage: window.ConsoleHomePageComponent as HomePageComponent,
  }

  private _config: AppConfig

  constructor(props: HomePageProps) {
    super(props)
    window.React = React
    window.ReactDOM = ReactDOM
    setConfig(props.env)
    const config = getConfig()
    this._config = config
  }

  static getDerivedStateFromError(): Pick<HomePageState, 'hasError'> {
    return { hasError: true }
  }

  componentDidCatch(error: Error): void {
    this.props.onError && this.props.onError(error)
  }

  componentDidMount(): void {
    const eventName = 'ConsoleHomepageInit'
    window.addEventListener(eventName as keyof WindowEventMap, (event) =>
      this.setComponent(event as HomePageInitEvent)
    )

    if (!this.state.HomePage) {
      void this.getHomePageBundle(this._config.homePageSrc)
    } else {
      this.onScriptLoad()
    }
  }

  setComponent = (event: HomePageInitEvent): void => {
    this.setState({ HomePage: event.detail })
    // @ts-expect-error
    window.ConsoleHomePageComponent = event.detail
  }

  getHomePageBundle(homePageUrl: string): void {
    try {
      this.createScript(homePageUrl, 'console-homepage-script')
    } catch (e) {
      console.error(e)
    }
  }

  onScriptError: OnScriptError = (): void => {
    if (!this.state.primaryScriptAttempted) {
      return this.setState(
        {
          primaryScriptAttempted: true,
        },
        () => void this.getHomePageBundle(this._config.homePageSrcBackup)
      )
    }
    this.setState({ hasScriptError: true })
  }

  onScriptLoad = (): void => {
    this.setState({ hasError: false, hasScriptError: false, homePageLoaded: true })
  }

  createScript = (src: string, id: string): void => {
    const original = document.getElementById(id)
    if (original) {
      original.remove()
    }
    const script = document.createElement('script')
    const head = document.querySelector('head')

    script.setAttribute('src', src)
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('id', id)
    script.setAttribute('crossorigin', 'true')
    script.onload = this.onScriptLoad
    script.onerror = this.onScriptError

    head?.appendChild(script)
  }

  render(): JSX.Element | null {
    const { navigate, accessToken, techSolutionId, env } = this.props
    const homePageProps = { navigate, accessToken, techSolutionId, env }
    const { HomePage, hasError, homePageLoaded, hasScriptError } = this.state
    const isInConsoleClient = !!navigate

    if (!homePageLoaded && !hasError && !hasScriptError) {
      return <Spinner />
    }

    if (hasError || (hasScriptError && isInConsoleClient)) {
      return <ErrorMessage name='platformconsole' />
    }

    return HomePage ? <HomePage {...homePageProps} /> : null
  }
}

// eslint-disable-next-line import/no-default-export
export default HomePageSource
