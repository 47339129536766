import {
  updateUserRecentsMutation,
  updateUserReleaseNotesLastSeenMutation,
} from '../graphql/mutations'
import { Query, getTechSolutionQuery, getReleaseNotesQuery, getUserQuery } from '../graphql/queries'
import { client } from './client'

export interface ReleaseNote {
  link: string | null
  title: string
  markdown: string
  dateReleased: string
}

export interface TechSolutionWithReleaseNotes {
  name: string
  releaseNotes: ReleaseNote[]
}

export interface ReleaseNotesResponse {
  techSolution: TechSolutionWithReleaseNotes
}

export interface User {
  newReleaseNotesCount: number
  releaseNotesLastSeen: string
}

export interface UserResponse {
  user: User
}

export interface TechSolution {
  id: string
  name: string
}

export interface TechSolutionResponse {
  techSolution: TechSolution
}

export interface ConsoleApiError {
  errorType: string
  message: string
}

interface ConsolePlatformResponse<T> {
  errors?: ConsoleApiError[]
  data: T
}

interface GetConsolePlatformParams {
  platformConsoleApiUrl: string
  accessToken: string
  techSolutionId: string
}

export interface RecentInput {
  id: string
  type: string
}

interface UpdateUserRecentsConsolePlatformParams {
  platformConsoleApiUrl: string
  accessToken: string
  input: RecentInput
}
interface UpdateUserRecentsResponse {
  updateUserRecents: {
    id: string
  }
}

export interface ReleaseNotesLastSeenInput {
  techSolutionId: string
}

interface UpdateReleaseNotesLastSeenParams {
  platformConsoleApiUrl: string
  accessToken: string
  input: ReleaseNotesLastSeenInput
}

interface UpdateReleaseNotesLastSeenResponse {
  updateUserReleaseNotesLastSeen: {
    releaseNotesLastSeen: string
  }
}

interface GetConsolePlatformData {
  <T, I = void>(
    platformConsoleApiUrl: string,
    query: Query<I>,
    accessToken: string,
    withErrors: true
  ): Promise<ConsolePlatformResponse<T>>
  <T, I = void>(
    platformConsoleApiUrl: string,
    query: Query<I>,
    accessToken: string,
    withErrors?: false
  ): Promise<T>
}

const getConsolePlatformData: GetConsolePlatformData = async <T, I = void>(
  platformConsoleApiUrl: string,
  query: Query<I>,
  accessToken: string,
  withErrors = false
) => {
  const { data, errors } = await client.post<Query<I>, ConsolePlatformResponse<T>>(
    platformConsoleApiUrl,
    query,
    {
      Authorization: accessToken.toString(),
    }
  )

  if (errors) {
    console.error(` Error fetching console api data: ${JSON.stringify(errors)}`)
  }

  if (withErrors) {
    return { data, errors }
  }

  return data
}

export const getTechSolution = async ({
  platformConsoleApiUrl,
  accessToken,
  techSolutionId,
}: GetConsolePlatformParams): Promise<TechSolutionResponse> =>
  await getConsolePlatformData<TechSolutionResponse>(
    platformConsoleApiUrl,
    getTechSolutionQuery(techSolutionId),
    accessToken
  )

export const getReleaseNotes = async ({
  platformConsoleApiUrl,
  accessToken,
  techSolutionId,
}: GetConsolePlatformParams): Promise<ReleaseNotesResponse> =>
  await getConsolePlatformData<ReleaseNotesResponse>(
    platformConsoleApiUrl,
    getReleaseNotesQuery(techSolutionId),
    accessToken
  )

export const getUser = async ({
  platformConsoleApiUrl,
  accessToken,
  techSolutionId,
}: GetConsolePlatformParams): Promise<UserResponse> =>
  await getConsolePlatformData<UserResponse>(
    platformConsoleApiUrl,
    getUserQuery(techSolutionId),
    accessToken
  )

export const updateUserRecents = async ({
  platformConsoleApiUrl,
  accessToken,
  input,
}: UpdateUserRecentsConsolePlatformParams): Promise<UpdateUserRecentsResponse> =>
  await getConsolePlatformData<UpdateUserRecentsResponse, RecentInput>(
    platformConsoleApiUrl,
    updateUserRecentsMutation(input),
    accessToken
  )

export const updateUserReleaseNotesLastSeen = async ({
  platformConsoleApiUrl,
  accessToken,
  input,
}: UpdateReleaseNotesLastSeenParams): Promise<UpdateReleaseNotesLastSeenResponse> =>
  await getConsolePlatformData<UpdateReleaseNotesLastSeenResponse, ReleaseNotesLastSeenInput>(
    platformConsoleApiUrl,
    updateUserReleaseNotesLastSeenMutation(input),
    accessToken
  )
