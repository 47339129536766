export type HomePageEnvType = '-prod' | '-preprod' | '-dev'

export type EnvType = 'prod' | 'prod-stage' | 'nonprod' | 'nonprod-stage' | 'dev'

type EnvUrlType = '-dev' | '-nonprod-stage' | '' | '-nonprod' | '-stage'

interface Nark {
  platformId: string
  url: string
}

interface BaseConfig {
  aegisApiUrl: string
  apiBase: string
  env: EnvType
  manifestSrc: string
  manifestSrcBackup: string
  homePageSrc: string
  homePageSrcBackup: string
  nark: Nark
  platformConsoleApiUrl: string
  platformConsoleUrl: string
  qualtricsId?: string
  teamsApiUrl: string
}

export interface AppConfig extends BaseConfig {
  backupDir: string
  platformConsoleTechSolutionId: string
  prodDir: string
}

const platformConsoleTechSolutionId = '20d80335-a38e-42ac-b9ae-593978881a4c'

const prodDir = 'v3'

const backupDir = 'v3-backup'

const defaultManifestPath = `${prodDir}/asset-manifest.json`

const backupManifestPath = `${backupDir}/asset-manifest.json`

const apiBase = (env: EnvUrlType) => `https://console-app${env}.platforms.nike.com/`

const generateSourceURL = (env: EnvUrlType, path: string = defaultManifestPath) =>
  `${apiBase(env)}${path}`

const generateHomePageSourceURL = (env: HomePageEnvType = '-dev', isBackup?: boolean) => {
  const accountEnv = env === '-prod' ? '-prod' : '-test'
  const envSuffix = env === '-prod' ? '' : env
  const backupSuffix = isBackup ? '-backup' : ''
  return `https://mfe${envSuffix}.console${accountEnv}.nikecloud.com/@cx/tech-solution-homepage/0/current/dist/homepage-init${backupSuffix}.js`
}

const platformConsoleApiUrl = (env: EnvUrlType) =>
  `https://api${env}-console.platforms.nike.com/graphql`

const platformConsoleUrl = (env: EnvUrlType) => `https://console${env}.platforms.nike.com`

const narkPreprod: Nark = {
  platformId: '==41iqF9u0PANj5EPl0Eixb',
  url: 'https://api-preprod.nark.nikecloud.com/v1',
}

const narkProd: Nark = {
  platformId: '==w2rMyMqTG0Df-M6_x02Vi',
  url: 'https://api.nark.nikecloud.com/v1',
}

const dev: BaseConfig = {
  apiBase: apiBase('-dev'),
  platformConsoleApiUrl: platformConsoleApiUrl('-dev'),
  platformConsoleUrl: platformConsoleUrl('-dev'),
  teamsApiUrl: 'https://api-teams.preprod.niketech.com/v1/teams/',
  manifestSrc: generateSourceURL('-dev'),
  manifestSrcBackup: generateSourceURL('-dev', backupManifestPath),
  homePageSrc: generateHomePageSourceURL('-dev'),
  homePageSrcBackup: generateHomePageSourceURL('-dev', true),
  nark: narkPreprod,
  aegisApiUrl: 'https://api.aegis.nikecloud.com/v1/qa/users',
  env: 'dev',
}

const nonprodStage: BaseConfig = {
  apiBase: apiBase(''),
  platformConsoleApiUrl: platformConsoleApiUrl('-nonprod-stage'),
  platformConsoleUrl: platformConsoleUrl('-nonprod-stage'),
  teamsApiUrl: 'https://api-teams.preprod.niketech.com/v1/teams/',
  manifestSrc: generateSourceURL(''),
  manifestSrcBackup: generateSourceURL('', backupManifestPath),
  homePageSrc: generateHomePageSourceURL('-preprod'),
  homePageSrcBackup: generateHomePageSourceURL('-preprod', true),
  nark: narkPreprod,
  aegisApiUrl: 'https://api.aegis.nikecloud.com/v1/qa/users',
  env: 'nonprod-stage',
}

const nonprod: BaseConfig = {
  apiBase: apiBase(''),
  platformConsoleApiUrl: platformConsoleApiUrl('-nonprod'),
  platformConsoleUrl: platformConsoleUrl('-nonprod'),
  teamsApiUrl: 'https://api-teams.preprod.niketech.com/v1/teams/',
  manifestSrc: generateSourceURL(''),
  manifestSrcBackup: generateSourceURL('', backupManifestPath),
  homePageSrc: generateHomePageSourceURL('-preprod'),
  homePageSrcBackup: generateHomePageSourceURL('-preprod', true),
  nark: narkPreprod,
  qualtricsId: 'ZN_8uM7bw06phFrTE2',
  aegisApiUrl: 'https://api.aegis.nikecloud.com/v1/qa/users',
  env: 'nonprod',
}

const prodStage: BaseConfig = {
  apiBase: apiBase('-stage'),
  platformConsoleApiUrl: platformConsoleApiUrl('-stage'),
  platformConsoleUrl: platformConsoleUrl('-stage'),
  teamsApiUrl: 'https://api-teams.niketech.com/v1/teams/',
  manifestSrc: generateSourceURL('-stage'),
  manifestSrcBackup: generateSourceURL('-stage', backupManifestPath),
  homePageSrc: generateHomePageSourceURL('-prod'),
  homePageSrcBackup: generateHomePageSourceURL('-prod', true),
  nark: narkPreprod,
  aegisApiUrl: 'https://api.aegis.nikecloud.com/v1/prod/users',
  env: 'prod-stage',
}

const prod: BaseConfig = {
  apiBase: apiBase(''),
  platformConsoleApiUrl: platformConsoleApiUrl(''),
  platformConsoleUrl: platformConsoleUrl(''),
  teamsApiUrl: 'https://api-teams.niketech.com/v1/teams/',
  manifestSrc: generateSourceURL(''),
  manifestSrcBackup: generateSourceURL('', backupManifestPath),
  homePageSrc: generateHomePageSourceURL('-prod'),
  homePageSrcBackup: generateHomePageSourceURL('-prod', true),
  nark: narkProd,
  qualtricsId: 'ZN_8uM7bw06phFrTE2',
  aegisApiUrl: 'https://api.aegis.nikecloud.com/v1/prod/users',
  env: 'prod',
}

let appConfig: AppConfig = {} as AppConfig

export const setConfig = (env?: EnvType): void => {
  let config = dev
  const locationHasEnvURL = (envUrl: string) =>
    window.location.origin.includes(`//${envUrl}.platforms.nike.com`)

  if (env === 'prod' || locationHasEnvURL('console') || locationHasEnvURL('docs')) {
    config = prod
  } else if (env === 'prod-stage' || locationHasEnvURL('console-stage')) {
    config = prodStage
  } else if (env === 'nonprod' || locationHasEnvURL('console-nonprod')) {
    config = nonprod
  } else if (env === 'nonprod-stage' || locationHasEnvURL('console-nonprod-stage')) {
    config = nonprodStage
  } else if (
    env === 'dev' ||
    locationHasEnvURL('console-dev') ||
    locationHasEnvURL('docs-nonprod')
  ) {
    config = dev
  } else if (window.location.origin.includes('localhost')) {
    config = dev
  } else {
    config = prod
  }

  appConfig = {
    ...config,
    prodDir,
    backupDir,
    platformConsoleTechSolutionId,
  }
}

export const getConfig = (): AppConfig => appConfig
