import { Button } from '@nike/eds'

import styles from './appContent.styl'

interface LoginProps {
  onClick: () => void
}

export const Login = ({ onClick }: LoginProps): JSX.Element => (
  <div className={styles.loginMessage}>
    <h1>You must be logged in to view this page</h1>
    <Button onClick={onClick}>Login</Button>
  </div>
)
