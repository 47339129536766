import { Component, ReactNode, ErrorInfo } from 'react'

import { AppContent, AuthProps } from './AppContent'
import { SupportedBrowsers } from './BrowserSupport'
import { NavBar, NavBarConfig } from './NavBar'
import { NavNarkPlatform, createNavNarkPlatform } from './NavNarkPlatform'
import { Pendo } from './Pendo'
import { Qualtrics } from './Qualtrics'
import { ErrorMessage } from './components/ErrorMessage'
import { Spinner } from './components/Spinner'
import { getConfig, setConfig, EnvType, AppConfig } from './config'
import { DOMAIN_EXPRESSIONS } from './constants'
import styles from './styles/styles.styl'
import { updateUserRecents, getTechSolution } from './util/api/pc'
import { NarkContext } from './util/nark'

interface ManifestResponse {
  files: { [key: string]: string }
  entrypoints?: string[]
}

interface ConsoleNavProps {
  accessToken: string // Okta Access token; NOT client_id
  env?: EnvType // Optional override to environment configs
  showConsoleNav?: boolean // Source and Show Console Navigation whether on console domain or not, defaults to false
  navigate?: (href: string) => void
}

export interface IntegratedAppProps extends ConsoleNavProps {
  name: string // Application Name
  techSolutionId?: string // Tech Solution Id from Platform Console
  isLoggedIn: boolean // Bool, state of user auth. Used for disabling navigation
  hasAuthCheckFinished: boolean // Boolean of whether or not auth check is still pending
  children: ReactNode // Application children, must include NavBar component from Epic React UI
  slackURL: string // http url to client or web application
  useConsoleLoginScreen?: boolean // Specify whether you want to let Platform console render 'logged out' state (inludes login button). Defaults to true
  onLogin?: () => void // Callback when user is logged in on console provided login page. Only necessary IF useConsoleLoginScreen is true
  onError?: (error: Error, errorInfo: ErrorInfo) => void // Callback when error occurs, can be used to forward error to a reporting service
  showTopNav?: boolean // Show the top nav bar, defaults to true
  navBarConfig?: NavBarConfig // config for top nav
}

interface IntegratedAppState {
  noScript: boolean
  hasError: boolean
  hasScriptError: boolean // Error state specifically for nav script onerror
  navLoaded: boolean
  primaryScriptAttempted: boolean
}

interface OnScriptError {
  (): void
  (error?: Error, nark?: boolean): void
}

const createLink = (src: string, id: string): void => {
  const original = document.getElementById(id)
  if (original) {
    original.remove()
  }
  const link = document.createElement('link')
  const head = document.querySelector('head')

  link.setAttribute('href', src)
  link.setAttribute('rel', 'stylesheet')
  link.setAttribute('type', 'text/css')
  link.setAttribute('id', id)

  head?.appendChild(link)
}

const shouldInjectNavRequestScript = (showConsoleNav?: boolean): boolean => {
  // Always source in console domain && development
  const domain = window.location.origin
  const isMatch = DOMAIN_EXPRESSIONS.some((d) => d.test(domain))
  if (isMatch && window.localStorage.consoleAbort !== 'true') return true

  return !!showConsoleNav
}

class IntegratedApp extends Component<IntegratedAppProps, IntegratedAppState> {
  public static defaultProps = {
    useConsoleLoginScreen: true,
    accessToken: '',
    name: '',
    showTopNav: true,
  }

  state: IntegratedAppState = {
    noScript: false,
    hasError: false,
    hasScriptError: false,
    navLoaded: false,
    primaryScriptAttempted: false,
  }

  private _config: AppConfig
  private _nark: NavNarkPlatform
  private _techSolutionName: string

  constructor(props: IntegratedAppProps) {
    super(props)

    setConfig(props.env)
    const config = getConfig()

    this._config = config
    this._nark = createNavNarkPlatform({
      token: props.accessToken,
      ...config.nark,
    })
    this._techSolutionName = 'Platform Console'
  }

  static getDerivedStateFromError(): Pick<IntegratedAppState, 'hasError'> {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.props.onError && this.props.onError(error, errorInfo)

    if (error.message.includes('Console Nav Error')) {
      this._nark.logMicroNavException({ data: { message: JSON.stringify(errorInfo), error } })
      // Only load the backup if it's a Nav Error
      this.onScriptError(error, false)
    } else {
      this._nark.logException({ data: { message: JSON.stringify(errorInfo), error } })
    }
  }

  componentDidMount(): void {
    // Inject only if either domain match or platform requests visibility
    if (shouldInjectNavRequestScript(this.props.showConsoleNav)) {
      void this.getAssetManifest(this._config.manifestSrc, this._config.prodDir)
    } else {
      this.setState({ noScript: true })
    }

    void this.getTechSolution()

    // only track recent if it is a tech solution
    if (this.props.techSolutionId) {
      try {
        void updateUserRecents({
          platformConsoleApiUrl: this._config.platformConsoleApiUrl,
          input: {
            type: 'TECH_SOLUTION',
            id: this.props.techSolutionId,
          },
          accessToken: this.props.accessToken,
        })
      } catch (error) {
        console.error('Error requesting update user recents', error)
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<IntegratedAppProps>): void {
    // Refresh Token for nav
    if (prevProps.accessToken !== this.props.accessToken) {
      this.fireConsoleInitEvent()
    }
  }

  async getTechSolution(): Promise<void> {
    const { techSolution } = await getTechSolution({
      platformConsoleApiUrl: this._config.platformConsoleApiUrl,
      accessToken: this.props.accessToken,
      techSolutionId: this.props.techSolutionId || this._config.platformConsoleTechSolutionId,
    })
    this._techSolutionName = techSolution.name
  }

  async getAssetManifest(manifestUrl: string, srcDir: string): Promise<void> {
    try {
      const manifestReq = await fetch(manifestUrl)
      const manifest = (await manifestReq.json()) as ManifestResponse

      manifest?.entrypoints?.map((src, i) => {
        if (src.includes('css')) {
          createLink(`${this._config.apiBase}${srcDir}/${src}`, `console-link-${i}`)
        } else {
          this.createScript(`${this._config.apiBase}${srcDir}/${src}`, `console-script-${i}`)
        }
      })
    } catch (e) {
      this._nark.logException({
        data: { message: 'Failed to load asset-manifest.json', error: e },
      })
    }
  }

  onScriptError: OnScriptError = (error?: Error, nark = true): void => {
    if (!this.state.primaryScriptAttempted) {
      if (nark) {
        this._nark.logMicroLoadBackup({ data: { message: 'Failed to load script', error } })
      }
      return this.setState(
        {
          primaryScriptAttempted: true,
        },
        () => void this.getAssetManifest(this._config.manifestSrcBackup, this._config.backupDir)
      )
    }
    this.setState({ hasScriptError: true })
  }

  onScriptLoad = (): void => {
    this.setState({ hasError: false, hasScriptError: false, navLoaded: true }, () => {
      // Create the console-navigation element so it's not associated to this react component
      // if the container is mounted as a child, react will throw an error the element is not a child
      const navRootId = 'nav-root'
      if (!document.getElementById(navRootId)) {
        const container = document.getElementById('console-integrated-platform')
        const div = document.createElement('div')
        div.setAttribute('id', navRootId)
        div.setAttribute('class', styles.navArea)
        container?.prepend(div)
      }
      this.fireConsoleInitEvent()
    })
  }

  fireConsoleInitEvent = (): void => {
    const detail = this.getConsoleNavProps()

    // Dispatch event notifying console-navigation that the div element is in the DOM
    const EventObj = { detail }
    const event = new window.CustomEvent('ConsoleInit', EventObj)
    window.dispatchEvent(event)
  }

  createScript = (src: string, id: string): void => {
    const original = document.getElementById(id)
    if (original) {
      original.remove()
    }
    const script = document.createElement('script')
    const head = document.querySelector('head')

    script.setAttribute('src', src)
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('id', id)
    script.setAttribute('crossorigin', 'true')
    script.onload = this.onScriptLoad
    script.onerror = this.onScriptError

    head?.appendChild(script)
  }

  getApplicationProps = (): AuthProps => {
    const { onLogin, children, isLoggedIn, useConsoleLoginScreen, hasAuthCheckFinished } =
      this.props

    return {
      onLogin,
      children,
      isLoggedIn,
      useConsoleLoginScreen,
      hasAuthCheckFinished,
    }
  }

  getConsoleNavProps = (): ConsoleNavProps => {
    const { navigate, accessToken, showConsoleNav, env } = this.props
    return { accessToken, showConsoleNav, navigate, env }
  }

  render(): JSX.Element {
    const { name, slackURL, navigate, showTopNav, navBarConfig, accessToken, techSolutionId } =
      this.props
    const { hasError, noScript, navLoaded, hasScriptError } = this.state
    const isInConsoleClient = !!navigate
    const qualtricsId = this._config.qualtricsId
    const config = getConfig()

    const nark = createNavNarkPlatform({
      token: accessToken,
      ...config.nark,
    })

    if (!noScript && !navLoaded && !hasError && !hasScriptError) {
      return <Spinner />
    }

    if (hasError || (hasScriptError && isInConsoleClient)) {
      return <ErrorMessage name={name} slackURL={slackURL} />
    } else {
      const { isLoggedIn, hasAuthCheckFinished } = this.props

      const disableNav =
        !isLoggedIn && hasAuthCheckFinished ? <div className={styles.disableNavigation} /> : null

      return (
        <SupportedBrowsers>
          {qualtricsId && (
            <Qualtrics
              accessToken={accessToken}
              qualtricsId={qualtricsId}
              techSolutionName={this._techSolutionName}
            />
          )}
          {['nonprod', 'prod'].includes(this._config.env) && (
            <Pendo
              accessToken={accessToken}
              techSolutionId={techSolutionId || this._config.platformConsoleTechSolutionId}
              techSolutionName={this._techSolutionName}
            />
          )}
          <div id='console-integrated-platform' className='integratedNavContainer'>
            {disableNav}
            <NarkContext.Provider value={nark}>
              <div id='cip-content-area' className={styles.contentArea}>
                {showTopNav && (
                  <NavBar
                    accessToken={accessToken}
                    techSolutionId={techSolutionId}
                    config={navBarConfig}
                  />
                )}
                <AppContent {...this.getApplicationProps()} />
              </div>
            </NarkContext.Provider>
          </div>
        </SupportedBrowsers>
      )
    }
  }
}

// eslint-disable-next-line import/no-default-export
export default IntegratedApp
export { default as HomePage } from './HomePage'
export { NavBar as IntegratedNavBar } from './NavBar'
