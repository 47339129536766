import { getConfig } from '../config'
import { defaultTechSolution, qualtricsStorageKey } from './consts'

interface QualtricsData {
  techSolution?: string | null
  firstName?: string
  lastName?: string
  email?: string
  jobTitle?: string
}

export const setQualtricsData = (data: QualtricsData): void => {
  const config = getConfig()

  if (!config.qualtricsId) {
    return
  }

  if (typeof data !== 'object') {
    throw new Error('data must be an object')
  }

  // set back to PC when not on a tech solution page
  if (data.techSolution === null) {
    data.techSolution = defaultTechSolution
  }

  const previous = (JSON.parse(sessionStorage?.getItem(qualtricsStorageKey) || '{}') ||
    {}) as QualtricsData

  sessionStorage.setItem(
    qualtricsStorageKey,
    JSON.stringify({
      ...previous,
      ...data,
    })
  )
}
