import { createContext } from 'react'

import { NavNarkPlatform } from '../NavNarkPlatform'
import { ConsoleApiError } from './api/pc'

interface LogException {
  source: string
  operation: string
  feature: string
  data: {
    techSolutionId?: string
    name?: string
    error?: Record<string, unknown> | string | unknown | ConsoleApiError[]
  }
}

export const NarkContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  logException: (data: LogException) => ({
    source: '',
    operation: '',
    feature: '',
    data: { techSolutionId: '', name: '' },
  }),
} as unknown as NavNarkPlatform)
