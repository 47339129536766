type ApiHeaders = Record<string, string>

interface ApiClient {
  post<P, R>(url: string, payload: P, headers: ApiHeaders): Promise<R>
  get<R>(url: string, options?: RequestInit): Promise<R>
}

const CONTENT_TYPE = {
  json: 'application/json',
  octetStream: 'application/octet-stream',
  javascript: 'application/javascript',
  css: 'text/css',
} as const

const processError = async (response: Response, contentType?: string) => {
  if (contentType?.includes(CONTENT_TYPE.json)) {
    return response.json()
  }
  throw new Error(response.status.toString())
}

const processResponse = (response: Response) => {
  const contentType = response?.headers?.get('content-type')?.replace(/ /g, '').toLowerCase()

  if (!response.ok && response.type !== 'opaque') {
    return processError(response, contentType)
  }

  if (!(response.status >= 200 && response.status < 400)) {
    return processError(response, contentType)
  }

  if (contentType?.includes(CONTENT_TYPE.json)) {
    return response.json()
  } else if (contentType === CONTENT_TYPE.octetStream) {
    return response.blob()
  } else if (contentType === CONTENT_TYPE.javascript) {
    return response.text()
  } else if (contentType?.includes(CONTENT_TYPE.css)) {
    return response.text()
  }
  return response
}

export const client: ApiClient = {
  async post<P, R>(url: string, payload: P, headers: ApiHeaders): Promise<R> {
    const response = await fetch(url, {
      method: 'POST',
      headers: Object.assign({ 'Content-Type': CONTENT_TYPE.json }, headers),
      body: JSON.stringify(payload),
    })
    return processResponse(response)
  },
  async get<R>(url: string, options: RequestInit = {}): Promise<R> {
    options = Object.assign({}, { ...options })
    const response = await fetch(url, options)
    return processResponse(response)
  },
}
