import { ReactNode } from 'react'

import { Login } from './Login'

export interface AuthProps {
  isLoggedIn: boolean
  hasAuthCheckFinished: boolean
  useConsoleLoginScreen?: boolean
  onLogin?: () => void
  children: ReactNode
}

export const Auth = ({
  useConsoleLoginScreen,
  onLogin,
  children,
  hasAuthCheckFinished,
  isLoggedIn = true,
}: AuthProps): JSX.Element | null => {
  if (isLoggedIn) {
    return <>{children}</>
  }

  if (!hasAuthCheckFinished) {
    return null
  }

  return useConsoleLoginScreen && onLogin ? <Login onClick={onLogin} /> : <>{children}</>
}
