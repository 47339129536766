import {
  BotInfo,
  BrowserInfo,
  NodeInfo,
  ReactNativeInfo,
  SearchBotDeviceInfo,
} from 'detect-browser'

interface SupportMessage {
  supported: boolean
  message: string
}

const parseVersion = (version: string): number | undefined => {
  if (version.length) {
    try {
      const versionSplitted = version.split('.')
      return parseInt(versionSplitted[0])
    } catch (e) {
      return undefined
    }
  } else {
    return undefined
  }
}

export const eapBrowserSupport = (
  browserDetectObj: BrowserInfo | SearchBotDeviceInfo | BotInfo | NodeInfo | ReactNativeInfo | null
): SupportMessage => {
  const version = browserDetectObj?.version && parseVersion(browserDetectObj.version)

  switch (browserDetectObj && browserDetectObj.name) {
    case 'edge-chromium':
    case 'chrome':
    case 'opera':
    case 'ios':
    case 'crios':
    case 'fxios':
    case 'safari':
      return { supported: true, message: '' }

    case 'firefox':
      if (version && version >= 60) {
        return { supported: true, message: '' }
      } else {
        return {
          supported: false,
          message:
            'Only Firefox 60 and above is supported. Please update Firefox or use a Chromium browser.',
        }
      }

    case 'edge': {
      return {
        supported: false,
        message: 'EdgeHTML is not supported. Please use a Chromium browser.',
      }
    }

    case 'ie':
      return {
        supported: false,
        message: 'Internet Explorer is not supported.  Please use a Chromium browser.',
      }

    default:
      return {
        supported: false,
        message: 'Unknown browser type.  Please try Chrome.',
      }
  }
}
