import classnames from 'classnames'

import { Icon } from '@nike/eds'

import styles from './browserSupport.styl'

export interface UnsupportedBrowserProps {
  name: string
  version: string | null
  message: string
}

export const UnsupportedBrowser = ({
  name,
  version,
  message,
}: UnsupportedBrowserProps): JSX.Element => (
  <div className={styles.containerUnsupported}>
    <Icon name='NikeSwoosh' size='l' color='var(--eds-color-brand-orange)' />
    <h1 className={classnames(styles.heading, 'eds-type--display-4')}>UNSUPPORTED BROWSER</h1>
    <p className='eds-type--body-2'>
      <b>
        {name}/{version} is not supported.
      </b>
    </p>
    <p>
      {message.split('\n').map((m, i) => (
        <span key={i}>{m}</span>
      ))}
    </p>
    <a className={styles.link} href='https://www.google.com/chrome/'>
      Chrome
    </a>
  </div>
)
